import { flatten } from 'flat'
import getKeyByValue from '~/utils/getKeyByValue.js'

import { _colors as colors } from '#tailwind-config/theme'

const cols = flatten(colors, { delimiter: '-' })

function getTailwindColorName(col) {
    return getKeyByValue(cols, col)
}

function bgToText(col) {
    const map = {
        white: 'midblue',
        black: 'white',
        midblue: 'white',
        darkblue: 'white',
        darkerblue: 'white',
        lightblue: 'white',
        verylightblue: 'white',
        gold: 'white',
    }

    return map[col]
}

export { getTailwindColorName, bgToText }
